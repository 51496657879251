import Vue from "vue/dist/vue"
import * as bootstrap from "bootstrap"

// import VueCookies from 'vue-cookies'

function start_vue() {

  var vm = new Vue({
    el: "body #v",  // options

    data: {
      active_img: null,
      lang: 'en',
    },

    methods: {
    },

    computed: {

    },

    watch: {

    },

    mounted: function() {
      var _this = this;
      var els = document.getElementsByClassName("carousel");
      for (var i=0;i<els.length;i++) {
        var el = els[i];
        el.addEventListener('slide.bs.carousel', (e) => {
          var idx = e.to;
          var as = el.getElementsByTagName('a');
          for (var ai=0;ai<as.length;ai++) {
            if (ai!=idx) {
              as[ai].classList.remove('active');
            } else {
              as[ai].classList.add('active');
            }
          }
        });
      }


    },

  })
}

start_vue();
//window.addEventListener("turbo:load", start_vue);
