import Vue from "vue/dist/vue"

Vue.component('pimage', {

  components: {

  },

  props: {
    lang: {
      type: String,
    },

    image: {
      type: Object,
    },
  },


  data: function() {
    return {

    }

  },

  methods: {

  },

  template: `
  <div>
    <img :src="image.meta.url" :alt="image.meta.alt[lang]">
    </img>
  </div>
`

})
