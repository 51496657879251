// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"
import * as bootstrap from "bootstrap"

window.global = window; // ouchie hack
window.nanoajax = require('nanoajax')

//import Masonry from "masonry-layout";
//window.Masonry = Masonry;

import Vue from "vue/dist/vue"
/*
import * as PetiteVue from "petite-vue"
window.addEventListener("turbo:load", function() {
  console.log("Mounting petite-vue");
  PetiteVue.createApp().mount();
});
*/


import "./vue/components/product.js"
import "./vue/components/contact.js"
import "./vue/components/pimage.js"
import "./vue/components/transimage.js"
import "./vue/app.js"

console.log("Welcome to Princeton Infrared Technologies :)")
