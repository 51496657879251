import Vue from "vue/dist/vue"

Vue.component('product', {

  props: [],

  data: function () {
    return {
      active_img: 0,
    }
  },

  created() {

  },

  mounted() {

  },

  watch: {
  },

})
