import Vue from "vue/dist/vue"
import VueCompareImage from 'vue-compare-image';

Vue.component('transimage', {

  components: {
    VueCompareImage,
  },

  props: {
    image1: {
      type: Object,
    },
    image2: {
      type: Object,
    },
    lang: {
      type: String,
    },
  },


  data: function() {
    return {

    }

  },

  template: `
<VueCompareImage :leftImage="image1.meta.url" :rightImage="image2.meta.url" :leftImageAlt="image1.meta.alt[lang]" :rightImageAlt="image2.meta.alt[lang]" />
`,


})
