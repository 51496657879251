import Vue from "vue/dist/vue"

Vue.component('contact', {

  props: [ "lang" ],

  data: function() {
    return {
      submitted: false,
      submitting: false,
      name: "",
      email: "",
      phone_number: "",
      company: "",
      message: "",
    }

  },

  methods: {
    submit: function() {

      var _this = this;

      var response = grecaptcha.getResponse();

      if(response.length == 0) {
        console.log("reCaptcha not verified");
        return
      }

      //if (this.email.length==0) {
      //  alert("Please provide your email address.");
      //  return;
      //}
      var b = []
      var f = ["name","email","phone_number","company","message","lang"];
      for (var i=0;i<f.length;i++) {
        b.push("contact[" + f[i] + "]=" + encodeURIComponent(this[f[i]] || ""));
      }
      b = b.join("&");

      _this.submitting = true;
      window.nanoajax.ajax({url: '/contacts', method: 'POST', body: b}, function (code, responseText, request) {
      _this.submitting = false;
        if (code==200) {
          _this.submitted = true;
          window.scroll({top: 0, left: 0, behavior: 'smooth'});

        }
      });
    },

    onVerify(resp) {
      //console.log("recaptcha verify",resp);
    },

    onExpired() {
      //console.log("recaptcha expired",resp);
      //this.$refs.recaptcha.reset();
    },

  },

  mounted() {

  },

})
